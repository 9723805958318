import { Box, Divider, Typography } from "@mui/material";
import { IconBarcode, IconCircleCheckFilled, IconCopy } from "@tabler/icons-react";

import { BounceInWidth } from "~/components/animations/bounce-in-width";
import { Button } from "~/components/button";
import { Pill } from "~/components/pill";
import { Routes } from "~/constants/routes";
import { useCopyWithLoading } from "~/hooks/use-copy-with-loading";
import { useOrigin } from "~/hooks/use-origin";

import { type ReferralCode, ReferralCodeStatus } from "../types";

export function ReferralCodeCard({ code: { code, status } }: { code: ReferralCode }) {
  const origin = useOrigin();
  const wisherInviteLink = `${origin}${Routes.public.invite({ code })}`;
  const gifterInviteLink = `${origin}${Routes.public.inviteGifter({ code })}`;
  const isActive = status === ReferralCodeStatus.ACTIVE;

  return (
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: 2,
        boxShadow: (theme) => theme.palette.shadows.primary[200],
        bgcolor: "neutral.0",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, bgcolor: "neutral.2", p: 1.5 }}>
        <Box sx={{ color: "neutral.5", height: "24px" }} component="span">
          <IconBarcode size={24} />
        </Box>
        <Typography variant="h5" color="neutral.7" component="span">
          {code}
        </Typography>
        <Pill
          variant={isActive ? "success" : "default"}
          sx={{
            ml: "auto",
            borderColor: isActive ? "green.3" : "neutral.3",
            borderWidth: 1,
            borderStyle: "solid",
          }}
        >
          {status}
        </Pill>
      </Box>
      <Box sx={{ p: 1.5, display: "flex", flexDirection: "column", gap: 1.5 }}>
        <Box>
          <Typography variant="sh4" color="neutral.7">
            Wisher Referral Link
          </Typography>
          <RefLink link={wisherInviteLink} />
        </Box>
        <Divider sx={{ border: 1, color: "neutral.2" }} />
        <Box>
          <Typography variant="sh4" color="neutral.7">
            Gifter Referral Link
          </Typography>
          <RefLink link={gifterInviteLink} />
        </Box>
      </Box>
    </Box>
  );
}

export function RefLink({ link }: { link: string }) {
  const [copyState, copy] = useCopyWithLoading({ loadingDuration: 500 });

  function copyButtonIcon() {
    if (copyState === "loading") return undefined;
    if (copyState === "success") {
      return (
        <BounceInWidth sx={{ display: "inline-flex", color: "primary.4" }}>
          <IconCircleCheckFilled size={20} />
        </BounceInWidth>
      );
    }
    return <IconCopy size={20} />;
  }

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Typography variant="b4" color="neutral.5" noWrap>
        {link}
      </Typography>
      <Box sx={{ flexShrink: 0, ml: "auto" }}>
        <Button
          size="sm"
          variant="subtle"
          endIcon={copyButtonIcon()}
          loading={copyState === "loading"}
          onClick={async () => copy(link)}
          sx={{ width: 100 }}
        >
          {copyState === "success" ? <span>Copied</span> : <span>Copy</span>}
        </Button>
      </Box>
    </Box>
  );
}
