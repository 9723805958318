import type { Theme } from "@mui/material";
import type { SxProps } from "@mui/system";

export const referralTableStyles: SxProps<Theme> = {
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "neutral.2",
  borderRadius: 2,
  boxShadow: (theme) => theme.palette.shadows.primary[100],

  "& .MuiTable-root": {
    tableLayout: {
      xs: "auto",
      md: "fixed",
    },
  },

  "& .MuiTableHead-root": {
    backgroundColor: "neutral.1",
    "& .MuiTableCell-root": {
      typography: "sh5",
      color: "neutral.5",
    },
  },

  "& .MuiTableCell-root": {
    borderColor: "neutral.2",
    whiteSpace: {
      xs: "nowrap",
      md: "normal",
    },
    wordBreak: "break-word",
  },

  "& .MuiTableBody-root": {
    backgroundColor: "neutral.0",

    "& .MuiTableRow-root:last-of-type": {
      "& .MuiTableCell-root": {
        borderBottom: "none",
      },
    },
  },
};
