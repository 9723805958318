import type { PaginationProps as MuiPaginationProps } from "@mui/material";
import { Pagination as MuiPagination, PaginationItem } from "@mui/material";
import { Link } from "@remix-run/react";

import { useScreen } from "~/hooks/use-screen";

type PaginationProps = MuiPaginationProps & {
  count: number;
  url?: string;
  page?: number;
  preventScrollReset?: boolean;
};

export function Pagination({
  url,
  count,
  page = 1,
  preventScrollReset,
  boundaryCount,
  showFirstButton,
  showLastButton,
  hideNextButton,
  hidePrevButton,
  ...props
}: PaginationProps) {
  const screen = useScreen();
  const isMobileScreen = screen === "mobile";

  const searchIndex = url?.indexOf("?");
  const basePath = searchIndex === -1 ? url : url?.slice(0, searchIndex);
  const search = searchIndex === -1 ? "" : url?.slice(searchIndex);
  const searchParams = new URLSearchParams(search);

  return (
    <MuiPagination
      count={count}
      page={page}
      renderItem={(item) => {
        if (!url) return <PaginationItem slots={{}} {...item} />;

        const itemSearchParams = new URLSearchParams(searchParams);
        itemSearchParams.set("page", String(item.page));

        return (
          <PaginationItem
            component={Link}
            to={`${basePath}?${itemSearchParams.toString()}`}
            slots={{}}
            preventScrollReset={preventScrollReset}
            {...item}
          />
        );
      }}
      boundaryCount={boundaryCount ?? (isMobileScreen ? 0 : 1)}
      showFirstButton={showFirstButton ?? isMobileScreen}
      showLastButton={showLastButton ?? isMobileScreen}
      hideNextButton={hideNextButton ?? isMobileScreen}
      hidePrevButton={hidePrevButton ?? isMobileScreen}
      {...props}
    />
  );
}
