import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "@remix-run/react";
import { IconInbox } from "@tabler/icons-react";

import { Pill } from "~/components/pill";
import { Routes } from "~/constants/routes";
import { dayjs } from "~/utils/dayjs";

import type { Referral } from "../types";
import { referralTableStyles } from "../utils/styles";

type ReferralTableProps = {
  referrals: Referral[];
};

export function ReferralTable({ referrals }: ReferralTableProps) {
  return (
    <TableContainer sx={referralTableStyles}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: { xs: 135, lg: 145 },
              }}
            >
              Sign Up Date
            </TableCell>
            <TableCell
              sx={{
                width: { xs: 124, sm: 188, md: 300 },
              }}
            >
              Referees
            </TableCell>
            <TableCell
              sx={{
                width: { xs: 120, md: 145 },
              }}
            >
              Code used
            </TableCell>
            <TableCell
              sx={{
                width: { xs: 80, md: 145 },
              }}
            >
              Type
            </TableCell>
            <TableCell
              sx={{
                width: { xs: 120, md: 145 },
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referrals.map((referral) => (
            <TableRow key={referral.id}>
              <TableCell>
                <Typography variant="b5" component="span" sx={{ whiteSpace: "pre" }}>
                  {dayjs(referral.activatedAt).format("MMM DD, YYYY,\nhh:mm A")}
                </Typography>
              </TableCell>
              <TableCell>
                <Referee referee={referral.referee} />
              </TableCell>
              <TableCell>{referral.code.code}</TableCell>
              <TableCell>{referral.referee.gifter ? "Gifter" : "Wisher"}</TableCell>
              <TableCell>
                <Pill variant={referral.referee.status === "active" ? "success" : "default"}>
                  {referral.referee.status}
                </Pill>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {referrals.length === 0 && (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: 2,
            py: 4,
            bgcolor: "neutral.0",
          }}
        >
          <Box
            sx={{
              borderRadius: 999,
              bgcolor: "neutral.2",
              width: 64,
              height: 64,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "neutral.6",
            }}
          >
            <IconInbox size={32} />
          </Box>
          <Box sx={{ mt: 3 }} />
          <Typography variant="bh7" color="neutral.7" component="div">
            No referees yet
          </Typography>
          <Box sx={{ mt: 2 }} />
          <Typography variant="b5" color="neutral.5" component="div">
            Once you start getting referees, we'll shown information about those signups here.
          </Typography>
        </Box>
      )}
    </TableContainer>
  );
}

function Referee({ referee }: { referee: Referral["referee"] }) {
  const username = referee.gifter?.username || referee.wisher?.username;

  if (!username) {
    return (
      <Typography variant="b5" color="neutral.5">
        username not created
      </Typography>
    );
  }

  const url = referee.gifter
    ? Routes.public.gifter({ username })
    : Routes.public.wisher({ username });

  return (
    <Typography variant="b5" color="primary.4" component={Link} to={url} className="notranslate">
      @{username}
    </Typography>
  );
}
