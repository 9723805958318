import { Box, type SxProps, type Theme, Typography } from "@mui/material";

import { msToDays } from "~/utils/helpers";

type ReferralDurationsTimelineProps = {
  durations: {
    rate: number;
    time: number;
  }[];
};
export function ReferralDurationsTimeline(props: ReferralDurationsTimelineProps) {
  // Filter out duplicate rates to merge the timeline if the rates are the same
  // E.g. 5% (day 0 -> 1) ------ 5% (day 1 -> 2) ------ 0% (Remaining days)
  // =>   5% (day 0 -> 2) ------------------------------0% (Remaining days)
  const durations = props.durations.filter((duration, index) => {
    const nextDuration = props.durations[index + 1];
    if (!nextDuration) return true;
    return duration.rate !== nextDuration.rate;
  });

  return (
    <Box sx={{ display: "flex" }}>
      {durations.map((duration, index) => {
        const previousDuration = durations[index - 1];
        const isLast = index === durations.length - 1;
        return (
          <Box key={duration.time} sx={{ flex: "1 1 0%", position: "relative" }}>
            <Box
              sx={{
                height: 8,
                mt: 0.75,
                mb: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transform: `translate(${isLast ? 0 : "8px"})`,
                borderRadius: 999,
                ...getDurationStyle(index, duration.rate),
              }}
            />
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                height: 20,
                px: "6px",
                borderRadius: 999,
                ...getDurationStyle(index, duration.rate),
              }}
            >
              <Typography variant="sh3">{duration.rate * 100}%</Typography>
            </Box>
            {isLast ? (
              <Typography variant="b4" color="neutral.5">
                Remaining days
              </Typography>
            ) : (
              <Typography variant="b4" color="neutral.5">
                Day {msToDays(previousDuration?.time || 0)} to {msToDays(duration.time)}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

function getDurationStyle(index: number, rate: number): SxProps<Theme> {
  if (rate === 0) return { bgcolor: "neutral.2", color: "neutral.6" };
  if (index === 0) return { bgcolor: "primary.4", color: "neutral.1" };
  if (index === 1) return { bgcolor: "primary.3", color: "primary.6" };

  return {
    bgcolor: "#D3EEF7",
    color: "primary.6",
  };
}
