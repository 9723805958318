import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { IconTrendingDown, IconTrendingUp } from "@tabler/icons-react";
import { type ReactNode, useState } from "react";

import { InfoCircleIcon } from "~/components/icons/info-circle-icon";

type StatCardProps = {
  title: string;
  value: ReactNode;
  helperText: string;
  percent?: number;
  variant?: "primary" | "subtle";
  loading?: boolean;
};
export function ReferralStatCard({
  title,
  value,
  helperText,
  percent,
  variant = "primary",
  loading,
}: StatCardProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Box
      sx={{
        bgcolor: colorVariants[variant],
        p: { xs: 1.5, sm: 3 },
        borderRadius: { xs: "10px", sm: "12px" },
        opacity: loading ? 0.5 : 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4" color="neutral.6">
          {title}
        </Typography>
        <Tooltip
          title={helperText}
          arrow
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltipPlacementBottom": { mt: "8px !important" },
            },
          }}
          open={tooltipOpen}
          onClose={() => setTooltipOpen(false)}
          onOpen={() => setTooltipOpen(true)}
        >
          <IconButton sx={{ p: 0, color: "neutral.6" }} onClick={() => setTooltipOpen(true)}>
            <InfoCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ mt: 1 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography component="div" color="neutral.7" variant="bh8">
          {value}
        </Typography>
        {percent !== undefined && (
          <Typography
            component="div"
            variant="b4"
            color="neutral.6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {percent > 0 && "+"}
            {Math.round(percent * 100) / 100}%
            <Box sx={{ ml: 0.5, height: "16px", color: "neutral.7" }} component="span">
              {percent > 0 ? <IconTrendingUp size={16} /> : <IconTrendingDown size={16} />}
            </Box>
          </Typography>
        )}
      </Box>
    </Box>
  );
}

const colorVariants = {
  primary: "primary.1",
  subtle: "neutral.2",
};
